
import { updateAppConfig } from '#app'
import { defuFn } from '/root/projects/penguin-frontend/node_modules/defu/dist/defu.mjs'

const inlineConfig = {
  "nuxt": {
    "buildId": "c6882567-27b8-4cf1-92aa-fd458be5bf74"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /* #__PURE__ */ defuFn(inlineConfig)
